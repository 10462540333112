import React from 'react'
import { Form, Field } from 'formik'
import bemCx from 'bem-modifiers'
import { withNamespaces } from '>/i18n'

import { Button } from '~/components/Button'
import InputField from '~/components/form/InputField'
import FormHeading from '~/components/form/FormHeading'

import { handleEmailFieldChange } from '~/utils/helpers'

const RemindPasswordView = withNamespaces(['authorization', 'errors'])((props) => {
  const { t, modifiers, displayLink, goToForm, error, loading } = props

  return (
    <div className={bemCx('remind-password', modifiers)}>
      <FormHeading
        displayLink={displayLink}
        title={t('reminder.heading')}
        onClick={() => goToForm('login')}
        text={t('reminder.heading_login')}
        button={t('reminder.heading_login_button')}
        modifiers={modifiers}
      />
      <Form>
        <Field
          component={InputField}
          name='username'
          label={t('reminder.email')}
          modifiers='auth'
          required
          autofocus
          onChange={handleEmailFieldChange}
        />
        <div className={bemCx('remind-password__error', { 'show-error': error })}>
          {t('errors:common_error')}
        </div>
        <div className='remind-password__button-container'>
          <Button
            type='submit'
            className='remind-password__button'
            modifiers={['primary', 'full-width', 'medium', 'with-glow']}
            label={t('reminder.remind_button_label')}
            loading={loading}
            disables={loading}
          />
        </div>
      </Form>
    </div>
  )
})

export default RemindPasswordView
