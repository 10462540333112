import AppleLogin from 'react-apple-login'
import { withNamespaces } from '>/i18n'
import { withRouter } from 'next/router'
import { compose } from 'ramda'

import { AnchorButton } from '~/components/Button'

import config from '~/config'

const AppleButton = ({ router, type, t }) => {

  const handleClick = onClick => {
    const { pathname, query } = router

    localStorage.setItem('lastUrl', JSON.stringify({ pathname, query }))
    onClick && onClick()
  }

  return (
    <AppleLogin
      clientId={config().appleServiceId}
      redirectURI={`${config().clubUrl}/callback/apple`}
      scope='email name'
      responseType='code id_token'
      responseMode='form_post'
      usePopup={false}
      render={renderProps =>
        <AnchorButton
          modifiers={['full-width', 'space-between', 'apple']}
          icon='i-apple'
          label={t(`${type}.apple_button`)}
          onClick={() => handleClick(renderProps.onClick)}
        />
      }
    />
  )
}

export default compose(
  withRouter,
  withNamespaces(['authorization', 'errors']),
)(AppleButton)
