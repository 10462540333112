import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/client'
import get from 'lodash/get'

import RegisterFormContainer from './RegisterFormContainer'

import { GET_USER_DATA } from '~/utils/queries.gql'
import { RESET_PASSWORD } from '~/components/forms/RemindPasswordForm/RemindPasswordFormGql'

const CREATE_USER = gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $phoneNumber: String!
    $subscribed: Boolean
    $regulationAccepted: Boolean
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      phoneNumber: $phoneNumber
      subscribed: $subscribed
      regulationAccepted: $regulationAccepted
    ){
      user {
        id
        email
        phoneNumber
        firstName
        lastName
        verified
        salesmanagoContactId
        regulationStatus
      }
    }
  }
`

const GET_REGULATIONS = gql`
  query documents {
    documents {
      nodes {
        id
        type
      }
    }
  }
`

const RegisterForm = ({ reservationLoading, reservationError, handleSubmit, loading, ...props }) => {
  const { data: regulationsData, client } = useQuery(GET_REGULATIONS)

  const [createUser, { loading: userLoading, error: userError }] = useMutation(CREATE_USER, {
    update: (cache, { data: { createUser } }) => {
      cache.writeQuery({
        query: GET_USER_DATA,
        data: { user: createUser.user },
      })
    }
  })

  const [resetPassword, { loading: resetLoading, error: resetError }] = useMutation(RESET_PASSWORD)

  return (
    <RegisterFormContainer
      {...props}
      resetPassword={resetPassword}
      createUser={createUser}
      loading={userLoading || reservationLoading || resetLoading || loading}
      error={userError || reservationError || resetError}
      client={client}
      handleSignIn={handleSubmit}
      regulations={get(regulationsData, 'documents.nodes', [])}
    />
  )
}

export default RegisterForm
