import bemCx from 'bem-modifiers'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import config from '~/config'
import { withNamespaces } from '>/i18n'

import { AnchorButton } from '~/components/Button'

const FacebookButtonView = (props) => {
  const { t, facebookCallback, label, loading, error, hasNoEmailError } = props

  return (
    <div className='facebook-button'>
      <div className={bemCx('facebook-button__error', { 'show-error': error })}>
        { hasNoEmailError
          ? t('errors:no_email')
          : t('errors:common_error')}
      </div>
      <FacebookLogin
        appId={config().facebookAppId}
        callback={facebookCallback}
        isMobile={false}
        version='3.1'
        render={renderProps => (
          <AnchorButton
            modifiers={['full-width', 'facebook']}
            icon='i-facebook'
            label={label || t('login.facebook_button')}
            onClick={renderProps.onClick}
            loading={loading}
            disabled={loading}
          />
        )}
      />
    </div>
  )
}

export default withNamespaces(['authorization', 'errors'])(FacebookButtonView)
