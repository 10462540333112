import React from 'react'
import { ErrorMessage } from 'formik'
import { withNamespaces } from '>/i18n'
import bemCx from 'bem-modifiers'

import Checkbox from '~/components/Checkbox'

@withNamespaces('errors')
export default class CheckboxField extends React.Component {
  check = () => {
    const { field, form, disabled, handleChange } = this.props

    if (!disabled) {
      if (handleChange) {
        handleChange(field.name, field.value, form.setFieldValue)
      } else {
        form.setFieldValue(field.name, !field.value)
      }
    }
  }

  render () {
    const { field, label, labelMore = null, className, modifiers, groupError = false, t, ...props } = this.props

    return (
      <div className={bemCx('checkbox-field', modifiers)} >
        <Checkbox
          name={field.name}
          id={field.name}
          label={label}
          labelMore={labelMore}
          onClick={this.check}
          active={field.value}
          className={className}
          modifiers={modifiers}
          t={t}
          {...props}
        />
        {!groupError && <ErrorMessage
          name={field.name}
          render={(errorI18NKey) => <div className='checkbox-field__error'>{t(errorI18NKey)}</div>}
        />}
      </div>
    )
  }
}
