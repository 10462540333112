import gql from 'graphql-tag'
import { Mutation } from '@apollo/client/react/components'
import RemindPasswordFormContainer from './RemindPasswordFormContainer'

export const RESET_PASSWORD = gql`
  mutation resetPasswordRequest($email: String!) {
    resetPasswordRequest(email: $email) {
      sent
    }
  }
`

const RemindPasswordForm = (props) =>
  <Mutation mutation={RESET_PASSWORD}>
    {(resetPassword, { loading, error }) => (
      <RemindPasswordFormContainer
        {...props}
        resetPassword={resetPassword}
        error={error}
        loading={loading}
      />
    )}
  </Mutation>

export default RemindPasswordForm
