import React, { useState, useEffect } from 'react'
import { withNamespaces } from '>/i18n'
import { compose } from 'ramda'
import moment from 'moment'
import { withRouter } from 'next/router'

import RemindPasswordForm from '~/components/forms/RemindPasswordForm/RemindPasswordFormGql'
import RegisterForm from '~/components/forms/RegisterForm/RegisterFormGql'
import LoginForm from '~/components/forms/LoginForm/LoginFormGql'
import CheckEmail from '~/components/CheckEmail'

import { setCookies } from '~/utils/cookie'
import { updateQueryParam } from '~/utils/helpersWithoutRouter'

export const AuthenticateModal = props => {
  const { closeThisModal, defaultView = 'login', modalType = 'all', router } = props
  const [view, setView] = useState(defaultView)
  const setUserCookie = userId => setCookies('userId', userId, null, { expires: moment().add(10, 'years').toDate() })

  const removeRedirectQuery = () => {
    const { query } = router
    updateQueryParam(query, router, ['loginRedirect'], 'replace')
  }

  useEffect(() => {
    removeRedirectQuery()
  }, [])

  return (
    <div className='authenticate-modal'>
      {
        view === 'login' &&
        <LoginForm
          modifiers='auth-modal'
          displayLink={modalType === 'all'}
          goToForm={setView}
          closeThisModal={closeThisModal}
          setUserCookie={setUserCookie}
        />
      }
      {
        view === 'register' && modalType === 'all' &&
        <RegisterForm
          modifiers='auth-modal'
          displayLink
          goToForm={setView}
          closeThisModal={closeThisModal}
          setUserCookie={setUserCookie}
        />
      }
      {
        view === 'reminder' &&
        <RemindPasswordForm
          modifiers='auth-modal'
          displayLink
          closeThisModal={closeThisModal}
          goToForm={setView}
        />
      }
      {
        view === 'check-mail' &&
        <CheckEmail />
      }
    </div>
  )
}

export default compose(
  withRouter,
  withNamespaces(['modal', 'common']),
)(AuthenticateModal)
