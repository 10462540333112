import React, { useEffect } from 'react'
import { compose } from 'ramda'
import get from 'lodash/get'

import withLayout from '~/hoc/withLayout'
import useUser from '~/hooks/useUser'

import ImageTag from '~/components/ImageTag'
import Modal from '~/components/Modal'
import { commonModals } from '~/components/Modal/modalsList'

import AuthenticateModal from '~/components/modals/AuthenticateModal'
import { redirect } from '~/utils/helpers'
import config from '~/config'

const LoginContainer = ({
  festivalName,
  defaultRedirectPath = '/',
  router,
}) => {
  const { user } = useUser()

  useEffect(() => {
    if (user) {
      const redirectPath = get(router, 'query.redirectPath', defaultRedirectPath)
      redirect(redirectPath, undefined, { ignoreQueryKeys: ['redirectPath'] })
    }
  }, [user])

  return (
    <>
      <ImageTag
        src={`assets/logo/logo-${config(festivalName).festival.id}.svg`}
        className='auth__logo'
      />
      <div className='auth__form'>
        <AuthenticateModal />
      </div>
      <Modal components={commonModals} />
    </>
  )
}

export default compose(
  withLayout({
    contentClassName: 'auth',
    withHeader: false,
    namespaces: ['authorization'],
  }),
)(LoginContainer)
