import React from 'react'
import { withFormik } from 'formik'

import { remindPasswordSchema as validationSchema } from '~/utils/validation'

import RemindPasswordFormView from './RemindPasswordFormView'

const handleSubmit = async (values, { setStatus, props }) => {
  const { username } = values
  await props.resetPassword({ variables: { email: username } })
  props.goToForm('check-mail')
}

@withFormik({
  name: 'reminderForm',
  mapPropsToValues: () => ({
    username: '',
  }),
  validationSchema,
  handleSubmit,
})
export default class RemindPasswordForm extends React.Component {
  render () {
    return <RemindPasswordFormView {...this.props} />
  }
}
