import React, { useState } from 'react'
import bemCx from 'bem-modifiers'
import ImageTag from '~/components/ImageTag'

const More = ({ t, labelMore }) => {
  const [more, setMore] = useState(false)

  return (
    <>
      { more &&
        <span className='checkbox__label-text-more' dangerouslySetInnerHTML={{ __html: labelMore }} />
      }
      <div
        className='checkbox__more-container'
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setMore(!more)
        }}
      >
        <span className='checkbox__more'>
          {more ? t('order:hide') : t('order:extend')}
        </span>
      </div>
    </>
  )
}

const Checkbox = ({
  id,
  name,
  label,
  labelMore,
  active,
  disabled,
  onClick,
  required,
  className,
  modifiers,
  logo,
  t
}) => {

  const handleClick = (event) => {
    event.preventDefault()
    onClick && onClick(id)
  }

  const modifiersProp = Array.isArray(modifiers) ? modifiers : [modifiers]

  return (
    <div
      className={bemCx('checkbox', [{ active, required }, ...modifiersProp], className)}
      onClick={handleClick}
    >
      <label className='checkbox__label'>
        <span className='checkbox__box' />
        {logo && <ImageTag className='checkbox__logo' src={logo} />}
        <div className='checkbox__label-container'>
          <span
            className='checkbox__label-text'
            onClick={e => e.stopPropagation()}
            dangerouslySetInnerHTML={{ __html: label }}
          />
          { labelMore && <More t={t} labelMore={labelMore} />}
        </div>

        <input
          name={name}
          type='checkbox'
          className='checkbox__hidden-box'
          disabled={disabled}
        />
      </label>
    </div>
  )
}

export default Checkbox
