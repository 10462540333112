import gql from 'graphql-tag'
import { Mutation } from '@apollo/client/react/components'
import FacebookButtonContainer from './FacebookButtonContainer'
import { GET_USER_DATA } from '~/utils/queries.gql'

const FACEBOOK_SIGN_IN = gql`
  mutation FacebookSignIn($accessToken: String! ) {
    facebookSignIn(accessToken: $accessToken){
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        verified
        salesmanagoContactId
        regulationStatus
      }
    }
  }
`

const FacebookButton = (props) =>
  <Mutation
    mutation={FACEBOOK_SIGN_IN}
    update={(cache, { data: { facebookSignIn } }) => {
      cache.writeQuery({
        query: GET_USER_DATA,
        data: { user: facebookSignIn.user }
      })
    }}
  >
    {(facebookSignIn, { loading, error }) => (
      <FacebookButtonContainer
        {...props}
        facebookSignIn={facebookSignIn}
        loading={loading}
        error={error}
      />
    )}
  </Mutation>

export default FacebookButton
