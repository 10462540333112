import React from 'react'
import bemCx from 'bem-modifiers'
import { Field, Form } from 'formik'
import { withNamespaces, Trans } from '>/i18n'
import { isEmpty } from 'ramda'
import { get } from 'lodash'

import withFestivalContext from '~/hoc/withFestivalContext'

import FacebookButton from '~/components/FacebookButton/FacebookButtonGql'
import FormHeading from '~/components/form/FormHeading'
import InputField from '~/components/form/InputField'
import CheckboxField from '~/components/CheckboxField'
import { Button } from '~/components/Button'
// import AppleButton from '~/components/AppleButton'
import { Redirect } from '~/components/Link'

import { handleEmailFieldChange, getErrorMessageForGraphError, termsLabel } from '~/utils/helpers'

const RegisterFormView = (props) => {
  const {
    t,
    modifiers,
    loading,
    graphErrors = [],
    privacyPolicy,
    regulation,
    displayLink,
    goToForm,
    handleCheckboxChange,
    handleUnverifiedUserError,
  } = props

  return (
    <div className={bemCx('register-form', modifiers)}>
      <FormHeading
        displayLink={displayLink}
        onClick={() => goToForm('login')}
        title={t('register.heading')}
        text={t('register.heading_login')}
        button={t('register.heading_login_button')}
      />
      <FacebookButton
        {...props}
        label={t('register.facebook_button')}
      />
      {/* <AppleButton type='register' /> */}
      <Form>
        <Field
          name='firstName'
          component={InputField}
          label={t('register.first_name')}
          modifiers='auth'
          required
          autofocus
        />
        <Field
          name='lastName'
          component={InputField}
          label={t('register.last_name')}
          modifiers='auth'
          required
        />
        <Field
          name='email'
          component={InputField}
          label={t('register.email')}
          modifiers='auth'
          required
          onChange={handleEmailFieldChange}
        />
        <Field
          type='password'
          required
          name='password'
          component={InputField}
          label={t('register.password')}
          modifiers='auth'
        />
        <Field
          name='phoneNumber'
          required
          component={InputField}
          label={t('register.phone')}
          modifiers='auth'
        />
        <Field
          name='subscribed'
          label={t('register.consent_messaging')}
          labelMore={t('register.consent_messaging_more')}
          component={CheckboxField}
          modifiers={['modal', 'modal-first', 'auth']}
          handleChange={handleCheckboxChange}
        />
        <Field
          name='regulationAccepted'
          required
          label={termsLabel({ privacyPolicy, regulation, t })}
          component={CheckboxField}
          modifiers={['modal', 'auth']}
          handleChange={handleCheckboxChange}
        />
        <Field
          name='allTerms'
          label={t('register.consent_all')}
          component={CheckboxField}
          modifiers={['modal', 'modal-last', 'auth']}
          handleChange={handleCheckboxChange}
        />
        {
          graphErrors.map((error, i) => (
            <div
              key={i}
              className={bemCx(
                'register-form__error',
                {
                  'show-error': !isEmpty(error),
                  'link': error && error.errorType === 'unverified_user'
                }
              )}
              onClick={() => handleUnverifiedUserError(error)}
              dangerouslySetInnerHTML={{ __html: t(getErrorMessageForGraphError(error, true)) }}
            />
          ))
        }
        <Button
          type='submit'
          label={t('register.register_button')}
          modifiers={['primary', 'full-width', 'medium', 'with-glow']}
          disabled={loading}
          loading={loading}
        />
      </Form>
      <div className='register-form__promo'>{t('register.promo')}</div>
      <div
        className='register-form__info'
      >
        <Trans i18nKey='register.info'>
          {get(privacyPolicy, 'id')
            ? <Redirect
              route='regulation'
              params={{ id: privacyPolicy.id }}
              slug
              target='_blank'
            >
              {{ link: t('register.privacy_policy') }}
            </Redirect>
            : <span>{{ link: t('register.privacy_policy') }}</span>
          }
        </Trans>
      </div>
    </div>
  )
}

export default withFestivalContext(withNamespaces('authorization')(RegisterFormView))
