import { withFormik } from 'formik'
import { compose } from 'ramda'
import get from 'lodash/get'
import * as Sentry from '@sentry/node'

import { withGraphModalActions } from '~/hoc/withGraphModalActions'

import LoginFormView from './LoginFormView'

import { loginSchema as validationSchema } from '~/utils/validation'
import { formatGraphErrors, handleRegulationModal } from '~/utils/helpers'

const handleSubmit = async (values, { props }) => {
  const {
    signIn,
    closeThisModal,
    setIsUser,
    setUserCookie,
    openModal,
  } = props
  const { username, password } = values

  try {
    const { data } = await signIn({ variables: { email: username, password: password } })
    const userId = get(data, 'signIn.user.id')
    const regulationStatus = get(data, 'signIn.user.regulationStatus')

    if (userId) {
      Sentry.setUser({ id: userId, email: get(data, 'signIn.user.email') })
      handleRegulationModal({ regulationStatus, openModal })
      setUserCookie(userId)
    }
    setIsUser && setIsUser(true)
    closeThisModal && closeThisModal()
  } catch (err) {
    console.log(err) // eslint-disable-line no-console
  }
}

const LoginContainer = ({ error, ...props }) => {
  const graphQLErrors = get(error, 'graphQLErrors', [])
  const formattedErrors = formatGraphErrors(graphQLErrors)

  const handleUnverifiedUserError = async (error) => {
    if (!error || error.errorType !== 'unverified_user') return

    const { resetPassword, goToForm, values } = props
    try {
      await resetPassword({ variables: { email: values.username } })
      goToForm('check-mail')
    } catch (err) {
      console.log(err) // eslint-disable-line no-console
    }
  }

  return (
    <LoginFormView
      {...props}
      graphErrors={formattedErrors}
      handleUnverifiedUserError={handleUnverifiedUserError}
    />
  )
}

export default compose(
  withGraphModalActions,
  withFormik({
    name: 'loginForm',
    mapPropsToValues: () => ({
      username: '',
      password: '',
      terms: false
    }),
    validationSchema,
    handleSubmit,
  })
)(LoginContainer)
