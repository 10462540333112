import bemCx from 'bem-modifiers'

export default ({ title, displayLink, text, onClick, button, modifiers }) =>
  <div className={bemCx('form-heading', modifiers)}>
    <h2 className='form-heading__name'>
      {title}
    </h2>
    {displayLink && <div className='form-heading__switcher'>
      <div className='form-heading__text'>
        {text}
      </div>
      <div className='form-heading__link' onClick={onClick}>
        {button}
      </div>
    </div>}
  </div>
