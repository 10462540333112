import { withNamespaces } from '>/i18n'

export default withNamespaces('authorization')(({ t }) => {
  return (
    <div className='check-email'>
      <h2 className='check-email__heading'>
        {t('check_mail')}
      </h2>
    </div>
  )
})
