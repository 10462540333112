import FacebookButtonView from './FacebookButtonView'
import { path, prop } from 'ramda'
import get from 'lodash/get'

import { useGraphModalActions } from '~/hooks/useModal'

import { handleRegulationModal } from '~/utils/helpers'

const findBlankEmailError = ({ extensions }) => {
  if (!extensions) {
    return false
  }
  const emailIsBlank = (path(['details', 'email'], extensions) || []).find(({ error }) => error === 'blank')
  return (extensions.code === 'validation' && emailIsBlank)
}

const FacebookButton = (props) => {
  const { openModal } = useGraphModalActions()

  const passFbTokenToAPI = async (response) => {
    const { facebookSignIn, closeThisModal, setIsUser, setUserCookie } = props

    const { data } = await facebookSignIn({ variables: { accessToken: response.accessToken } })
    const userId = get(data, 'facebookSignIn.user.id')
    const regulationStatus = get(data, 'facebookSignIn.user.regulationStatus')

    userId && handleRegulationModal({ regulationStatus, openModal })
    userId && setUserCookie(userId)
    closeThisModal && closeThisModal()
    setIsUser && setIsUser(true)
  }

  return (
    <FacebookButtonView
      {...props}
      hasNoEmailError={(prop('graphQLErrors', props.error) || []).find(findBlankEmailError)}
      facebookCallback={passFbTokenToAPI}
    />
  )
}

export default FacebookButton
