import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from '@apollo/client/react/components'
import LoginFormContainer from './LoginFormContainer'
import { GET_USER_DATA } from '~/utils/queries.gql'
import { RESET_PASSWORD } from '~/components/forms/RemindPasswordForm/RemindPasswordFormGql'

const SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String! ) {
    signIn(email: $email, password: $password){
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        verified
        salesmanagoContactId
        regulationStatus
      }
    }
  }
`

const LoginForm = ({ handleSubmit, loading, ...props }) =>
  <Mutation
    mutation={SIGN_IN}
    update={(cache, { data: { signIn } }) => {
      cache.writeQuery({
        query: GET_USER_DATA,
        data: { user: signIn.user }
      })
    }}
  >
    {(signIn, { loading: signInLoading, error: signInError }) => {
      return (
        <Mutation mutation={RESET_PASSWORD}>
          {(resetPassword, { loading: resetLoading, error: resetError }) => {
            return (
              <LoginFormContainer
                {...props}
                resetPassword={resetPassword}
                signIn={signIn}
                loading={signInLoading || resetLoading || loading}
                error={signInError || resetError}
              />
            )
          }}
        </Mutation>
      )
    }}
  </Mutation>

export default LoginForm
