import React from 'react'
import { withFormik } from 'formik'
import { withRouter } from 'next/router'
import get from 'lodash/get'
import * as Sentry from '@sentry/node'

import { withGraphModalActions } from '~/hoc/withGraphModalActions'

import RegisterFormView from '~/components/forms/RegisterForm/RegisterFormView'

import { registerSchema as validationSchema } from '~/utils/validation'
import { formatGraphErrors, handleRegulationModal } from '~/utils/helpers'
import { REGULATION_TYPES } from '~/utils/consts'

const handleSubmit = async (values, { props }) => {
  const {
    createUser,
    connectReservation,
    handleSignIn,
    allowToConnectReservation,
    reservation,
    goToForm,
    router,
    setUserCookie,
    openModal,
  } = props

  try {
    const user = await createUser({ variables: values })
    const userId = get(user, 'data.createUser.user.id')
    const regulationStatus = get(user, 'data.createUser.user.regulationStatus')

    if (userId) {
      Sentry.setUser({ id: userId, email: get(user, 'createUser.user.email') })
      setUserCookie(userId)
      handleRegulationModal({ regulationStatus, openModal })
    }

    const isReservationConnected = reservation && reservation.user

    if (!isReservationConnected && allowToConnectReservation) {
      const code = get(router, 'query.reservationCode')

      await connectReservation({
        variables: {
          code,
          userId
        }
      })
    }

    handleSignIn && handleSignIn()
    goToForm && goToForm('check-mail')
  } catch (err) {
    console.log(err) // eslint-disable-line no-console
  }
}

@withRouter
@withGraphModalActions
@withFormik({
  name: 'registerForm',
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    allTerms: false,
    subscribed: false,
    regulationAccepted: false,
  }),
  validationSchema,
  handleSubmit,
})
export default class RegisterForm extends React.Component {

  handleConsents = (name, value) => {
    const { setFieldValue } = this.props

    if (name === 'allTerms' && !value) {
      setFieldValue('subscribed', true)
      setFieldValue('regulationAccepted', true)
    } else if (name !== 'allTerms' && value) {
      setFieldValue('allTerms', false)
    }

    setFieldValue(name, !value)
  }

  handleUnverifiedUserError = async (error) => {
    if (!error || error.errorType !== 'unverified_user') return

    const { resetPassword, goToForm, values } = this.props
    try {
      await resetPassword({ variables: { email: values.email } })
      goToForm('check-mail')
    } catch (err) {
      console.log(err) // eslint-disable-line no-console
    }
  }

  render () {
    const { error, regulations } = this.props

    const graphQLErrors = get(error, 'graphQLErrors', [])
    const formattedErrors = formatGraphErrors(graphQLErrors)

    const privacyPolicy = regulations.find(node => node.type === REGULATION_TYPES.PRIVACY_POLICY)
    const regulation = regulations.find(node => node.type === REGULATION_TYPES.MAIN_REGULATION)

    return (
      <RegisterFormView
        {...this.props}
        graphErrors={formattedErrors}
        privacyPolicy={privacyPolicy}
        regulation={regulation}
        handleCheckboxChange={this.handleConsents}
        handleUnverifiedUserError={this.handleUnverifiedUserError}
      />
    )
  }
}
