import React from 'react'
import bemCx from 'bem-modifiers'
import { Field, Form } from 'formik'
import { withNamespaces } from '>/i18n'
import { isEmpty } from 'ramda'

import FacebookButton from '~/components/FacebookButton/FacebookButtonGql'
import AppleButton from '~/components/AppleButton'
import FormHeading from '~/components/form/FormHeading'
import InputField from '~/components/form/InputField'
import { Button } from '~/components/Button'

import { handleEmailFieldChange, getErrorMessageForGraphError } from '~/utils/helpers'

const LoginFormView = (props) => {
  const { t, loading, modifiers, goToForm, closeModal, graphErrors, displayLink, handleUnverifiedUserError } = props

  return (
    <div className={bemCx('login-form', modifiers)}>
      <FormHeading
        displayLink={displayLink}
        onClick={() => goToForm('register')}
        title={t('login.heading')}
        text={t('login.heading_register')}
        button={t('login.heading_register_button')}
        modifiers={modifiers}
      />
      <FacebookButton
        {...props}
        closeModal={closeModal}
      />
      <AppleButton type='login' />
      <Form>
        <Field
          name='username'
          component={InputField}
          label={t('login.email')}
          modifiers='auth'
          required
          autofocus
          onChange={handleEmailFieldChange}
        />
        <Field
          name='password'
          component={InputField}
          type='password'
          label={t('login.password')}
          modifiers='auth'
          required
        />
        {graphErrors.map((error, i) => (
          <div
            key={i}
            className={bemCx(
              'login-form__error',
              {
                'show-error': !isEmpty(error),
                'link': error && error.errorType === 'unverified_user'
              }
            )}
            onClick={() => handleUnverifiedUserError(error)}
          >
            {t(getErrorMessageForGraphError(error))}
          </div>
        ))}
        <div className='login-form__button-container'>
          <Button
            type='submit'
            label={t('login.login_button_label')}
            modifiers={['primary', 'full-width', 'medium', 'with-glow']}
            disabled={loading}
            loading={loading}
          />
        </div>
      </Form>
      <Button
        label={t('login.reminder')}
        modifiers={['reminder', 'naked']}
        onClick={() => goToForm('reminder')}
      />
    </div>
  )
}

export default withNamespaces(['authorization', 'errors'])(LoginFormView)
